.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.text-wrapper {
  width: 50%;
  padding: 40px 0 0 40px;
}

.image-wrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-itself {
  max-height: 100vh;
  height: 550px;
}

.mobile-break {
  display: none;
}

.grant-eu {
  width: 300px;
}

.footer-wrapper {
  padding: 40px 0 0 40px;
}

h1 {
  font-weight: 400;
  font-size: 35px;
  margin: 0;
}

h3 {
  font-weight: 400;
  font-size: 12px;
}

p {
  font-size: 18px;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .text-wrapper {
    width: 295px;
  }

  h1 {
    width: 100px;
  }

  .image-wrapper {
    width: 100%;
  }

  .img-itself {
    height: 300px;
    padding-top: 25px;
  }

  .mobile-break {
    display: block;
  }

  .grant-eu {
    padding-top: 30px;
  }

  .footer-wrapper {
    padding: 0px 40px 0 40px;
  }
}
